
// import * as Lity from 'lity/dist/lity';
import 'lity';
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function() {

  $(document).on('af_complete', function(event, response) {
    var form = response.form; // Если у формы определённый id
    if (form.attr('id') == 'feedback-form') {
      form.hide();
      $('#feedback-form-success').show();
    }
    else {
      if (console) console.log(response)
    }
  });

  // $(document).on('click', '[data-lity]', lity);
});
